import { render, staticRenderFns } from "./FooterBar.vue?vue&type=template&id=507ca742&scoped=true"
import script from "./FooterBar.vue?vue&type=script&lang=js"
export * from "./FooterBar.vue?vue&type=script&lang=js"
import style0 from "./FooterBar.vue?vue&type=style&index=0&id=507ca742&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507ca742",
  null
  
)

export default component.exports